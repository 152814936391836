@use "./config/" as *;
@forward "./main-scss/";

.waf-contact {
    --_negative-space: 25rem;
    @extend %pt-12;
    &::before {
        content: '';
        z-index: var(--z-contact-bg);
        pointer-events: none;
        @extend %pos-tl;
        @extend %w-100;
        @extend %h-100;
        @include background(null , "pattern/mobile/contact-us.png", center / cover no-repeat);
    }    
    .waf-head {
        border-radius: 0 0 var(--full-radius) var(--full-radius);
        background: linear-gradient(1.19deg, var(--secondary-800) 0.6%, var(--primary-700) 99.11%);
        top: var(--space-10);
        @extend %mx-2;
        @extend %relative;
        @extend %py-6;
        @extend %px-2;
        &::before,&::after {
            content: "";
            pointer-events: none;
            width: 5rem;
            height: 8rem;
            @include position(-8rem,null,null,-1.6rem);
            @include background(null, "pattern/contact-left.png", bottom/100% no-repeat);
        }
        &::after {
            right: -1.5rem;
            left: unset;
            @include background(null, "pattern/contact-right.png", bottom/100% no-repeat);
        }
    }
    .head-wrap {
        flex-direction: column;
        align-items: flex-start;
        @extend %mb-6;
        .title {
            @extend %mb-1;
            @extend %pure-white-900;
            @extend %capitalize;
            @extend %font-14-pb;
        }
        .text {
            @extend %primary-50;
            @extend %font-14-pr;
        }
        &::before {
            content: '';
            pointer-events: none;
            width: calc(100% - 9rem);
            height: 2rem;
            top: -2rem;
            @extend %pos-x-center;
            @include background(null, "pattern/contact-center.png", top/100% no-repeat);
        }
    }
    .custom-form {
        @extend %gap-3;
        @extend %flex-column;
    }
    textarea {
        outline: 0;
        &.form-control {
            min-height: 10rem;
            @extend %py-3;
        }
    }
    .form {
        &-wrapper {
            padding-block: var(--space-14) var(--space-4);
            @extend %full-radius;
            @extend %px-2;
            @extend %pure-white-900-bg;
        }
        &-head {
            @extend %mb-6;
            .title {
                @extend %mb-1;
                @extend %neutral-800;
                @extend %font-24-pb;
            }
            .text {
                @extend %neutral-700;
                @extend %font-14-pm;
            }
        }
        &-control {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            height: 5rem;
            border: 0;
            @extend %neutral-500;
            @extend %font-16-pr;
            @extend %w-100;
            @extend %px-3;
            @extend %neutral-100-bg;
        }
        &-btn {
            .btn-submit {
                height: 4.4rem;
                @extend %flex-c-c;
                @extend %w-100;
                @extend %tertiary-600-bg;
                @extend %half-radius;
                .btn-text {
                    @extend %uppercase;
                    @extend %neutral-800;
                    @extend %font-14-pb;
                }
            }
        }
    }
    .contact {
        &-list {
            @extend %gap-6;
            @extend %flex-column;
        }
        &-item {
            @extend %gap-2;
            @extend %flex;
        }
        &-content {
            .title {
                @extend %mb-1;
                @extend %pure-white-900;
                @extend %capitalize;
                @extend %font-14-pm;
            }
            .text {
                @extend %primary-50;
                @extend %font-12-pr;
            }
        }
    }
    .icon {
        @extend %relative;
        @extend %font-0;
        &::before {
            width: 4rem;
            height: 4rem;
            @extend %pure-white-900;
            @extend %flex-c-c;
            @extend %circle-radius;
            @extend %primary-900-bg;
            @include icon(shop);
        }
        &.email {
            &::before {
                content: '\e82c';
            }    
        }
        &.contact {
            &::before {
                content: '\e82b';
            }    
        }
        &.address {
            &::before {
                content: '\e82d';
            }    
        }
    }
    .error-msg {
        @extend %font-10;
        @extend %mt-1;
        @extend %px-4;
        @extend %error-900;
    }
}
@include mq(col-md) {
    .waf-contact {
        &::before {
            @include background(null , "pattern/contact-us.png", left / cover no-repeat);
        }
        .head-wrap {
            &::before {
                width: 70%;
            }
        }
        .waf-head {
            margin-inline: var(--space-10);
            padding-inline: var(--space-6);
        }
        .form-wrapper {
            padding-inline: var(--space-10);
        }
    }
}
@include mq(col-lg) {
    .waf-contact {
        padding-block: var(--space-6);
        &::before {
            background-position: center;
        }
        .head-wrap {
            &::before {
                width: calc(100% - 9rem);
            }
        }
        .layout-wrapper {
            display: flex;
        }
        .waf-head {
            width: 30%;
            height: max-content;
            margin-inline: 0 calc(var(--_negative-space)*-1);
            padding-inline: var(--space-4);
            top: 10rem;
        }
        .waf-body {
            width: calc(70% + var(--_negative-space));
            height: calc(var(--window-inner-height) - var(--header-height));
        }
        .form-wrapper {
            padding-inline: calc(var(--_negative-space) + var(--space-10)) var(--space-10);
            padding-bottom: var(--space-14);
        }
        .icon {
            &::before {
                font-size: 2rem;
                width: 5rem;
                height: 5rem;
            }
        }
        .contact {
            &-content {
                .text {
                    font-size: 1.4rem;
                }
                .title {
                    font-size: 1.6rem;
                }
            }
        }
        .head-wrap {
            .title {
                font-size: 3.2rem;
                margin-bottom: var(--space-2);
            }
            .text {
                font-size: 1.6rem;
            }
        }
        .form {
            &-btn {
                .btn-submit {
                    width: 20rem;
                }
            }
        }
        .custom-form {
            flex-direction: row;
            flex-wrap: wrap;
        }
        .dw-50 {
            width: calc(50% - var(--space-2));
        }
    }
}